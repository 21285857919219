import TrackingService from "../tracking_service";
import BrandTile from "./brand_tile";
import {TrackingFeatureLabels} from "../types";
import {TrackingWidgetTile} from "../tracking_service";

export default class PromoBrandShopPromo {
    readonly promoType: string;
    readonly featureOrder: number;
    readonly filledSlots: number;
    readonly trackingWidgetTiles: TrackingWidgetTile[];
    readonly featureIndex: number;
    readonly featureId: string;
    readonly parentFeatureId: string;
    readonly masFeatureTrackingLabels: TrackingFeatureLabels;
    private readonly trackingService: TrackingService;

    constructor(container: Element) {

        this.promoType = this._getPromoType(container);
        this.featureOrder = this._getFeatureOrder(container);
        this.masFeatureTrackingLabels = this._getMasFeatureTrackingLabels(container);
        this.featureIndex = this._getFeatureIndex(container);
        this.featureId = this._getFeatureId(container)
        this.parentFeatureId = this.getParentFeatureTrackingId(this.promoType, this.featureId);
        const brandTileDoms = container.querySelectorAll('.promo_brandshoppromo--tile');
        this.trackingWidgetTiles = Array
            .from(brandTileDoms)
            .map((brandTileDom: Element, index: number) => new BrandTile(brandTileDom, index + 1))
            .map(brandTile => this.convertToTrackingWidgetTile(brandTile, this.promoType))
        this.filledSlots = this.trackingWidgetTiles.length;
        this.trackingService = new TrackingService();
        this.trackLoaded(container);
        this.registerScrollTrackingObserver(brandTileDoms);

        brandTileDoms.forEach((brandTileDom: Element, index: number) => {
            brandTileDom.addEventListener("click", this.trackClick(this.trackingWidgetTiles[index]), false);
        });
    }

    trackClick(trackingWidgetTile: TrackingWidgetTile): () => void {
        const self = this;
        return function () {
            self.trackingService.sendClickTrackingEvent(self.featureOrder, self.featureIndex, self.parentFeatureId, trackingWidgetTile, self.filledSlots, self.masFeatureTrackingLabels, self.promoType);
        }
    }

    trackLoaded(container: Element): void {
        if (!container.getAttribute("data-tracked")) {
            container.setAttribute("data-tracked", "true");
            this.trackingService.sendLoadedTracking(this.featureOrder, this.featureIndex, this.parentFeatureId, this.trackingWidgetTiles, this.masFeatureTrackingLabels, this.promoType);
        }
    }

    registerScrollTrackingObserver(brandTiles: NodeListOf<Element>): void {
        /*                                     */
        const trackingObserver = new IntersectionObserver((entries) => {
            const entriesToTrack = entries
                .filter((entry) => entry.isIntersecting);

            if (entriesToTrack.length > 0) {
                const positionsToTrack = entriesToTrack
                    .map((entry) => parseInt(entry.target.getAttribute("data-tile-position")!))

                this.trackingService.sendScrollTrackingEvent(this.parentFeatureId, positionsToTrack.map(position => this.getTileFeatureTrackingId(this.promoType, this.featureId, position)));

                entriesToTrack.forEach((entry) => {
                    trackingObserver.unobserve(entry.target);
                });
            }

        });
        brandTiles.forEach((brandTile) => {
            trackingObserver.observe(brandTile);
        });
    }

    _getPromoType(container: Element): string {
        return container.getAttribute("data-promo-type")!;
    }

    _getFeatureOrder(container: Element): number {
        const dataFeatureOrder = container.closest('[data-feature-order]')?.getAttribute('data-feature-order');
        return dataFeatureOrder ? parseInt(dataFeatureOrder, 0) : -1;
    }

    _getMasFeatureTrackingLabels(container: Element): TrackingFeatureLabels {
        const dataFeatureTrackingLabels = container.closest('[data-feature-tracking-labels]')?.getAttribute("data-feature-tracking-labels");
        return dataFeatureTrackingLabels ? JSON.parse(dataFeatureTrackingLabels) : {};
    }

    _getFeatureIndex(container: Element): number {
        return parseInt(container.getAttribute('data-feature-index')!, 0);
    }

    _getFeatureId(container: Element): string {
        return container.getAttribute('data-feature-id')!;
    }

    private convertToTrackingWidgetTile(brandTile: BrandTile, promoType: string): TrackingWidgetTile {
        return {
            id: this.getTileFeatureTrackingId(promoType, this.featureId, brandTile.position),
            position: brandTile.position,
            labels: {
                promo_Content: [brandTile.urlPath],
                promo_TargetPsr: [brandTile.dreson],
                promo_Source: ["manual"],
                promo_ImageId: [brandTile.imageId]
            }
        }
    }

    private getParentFeatureTrackingId(promoType: string, featureId: string) {
        return `ft3_bspr${this.getIdSuffix(promoType)}_${featureId}`;
    }

    private getTileFeatureTrackingId(promoType: string, featureId: string, tilePosition: number) {
        return `ft3_bspr${this.getIdSuffix(promoType)}_${featureId}_tile${tilePosition}`;
    }

    private getIdSuffix(promoType: string) {
        return (promoType === "BrandShopPromotionLarge") ? "la" : "sm";
    }

}
