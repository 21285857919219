import {Action, DataContainer, Feature, FeatureStatus, submitMerge, submitMiniAction, submitMove, trackOnNextPageImpression} from '@otto-ec/tracking-bct';
import {getPagecluster} from "@otto-ec/global-resources/misc";

export default class TrackingService {

    sendLoadedTracking(featureOrder: number, featureIndex: number, parentTrackingId: string, widgetTiles: TrackingWidgetTile[], masFeatureTrackingLabels: DataContainer, moduleType: string) {
        const trackingFeatures = [
            this.createWidgetFeature(featureOrder, featureIndex, parentTrackingId, widgetTiles.length, "loaded", masFeatureTrackingLabels, moduleType),
            ...widgetTiles.map(((widgetTile: TrackingWidgetTile) => {
                return this.createWidgetTileFeature(widgetTile, parentTrackingId, "loaded", moduleType)
            }))
        ];
        submitMerge({}, trackingFeatures);
    }

    sendClickTrackingEvent(featureOrder: number, featureIndex: number, parentFeatureId: string, widgetTile: TrackingWidgetTile, filledSlots: number, masFeatureTrackingLabels: DataContainer, moduleType: string) {
        const trackingFeatures = [
            this.createWidgetFeature(featureOrder, featureIndex, parentFeatureId, filledSlots, "clicked", masFeatureTrackingLabels, moduleType),
            this.createWidgetTileFeature(widgetTile, parentFeatureId, "clicked", moduleType)
        ]
        const trackingAction = {
            name: "click",
            features: trackingFeatures
        }
        const wkTrackingData = {
            "wk.promo_AttributionFeature": [`${moduleType}_${getPagecluster()}`],
            "wk.promo_AttributionSource": ["manual"]
        }

        submitMove(wkTrackingData, trackingAction as Action);
        trackOnNextPageImpression({"promo_Click": [moduleType]} as DataContainer);
    }

    sendScrollTrackingEvent(parentFeatureId: string, featureIds: string[]) {
        const parentFeature = {'featureId': parentFeatureId}
        const tileFeatures = featureIds.map(featureId => {
            return {'featureId': featureId}
        })
        submitMiniAction([parentFeature, ...tileFeatures]);
    }

    private createWidgetFeature(featureOrder: number, featureIndex: number, featureId: string, filledSlots: number, status: FeatureStatus, masFeatureTrackingLabels: DataContainer, moduleType: string): Feature {
        const labels = {
            promo_Feature: ["true"],
            promo_FilledSlots: [(filledSlots).toString()],
            promo_FeatureNumber: [(featureIndex + 1).toString()]
        };
        const mergedLabels = {
            ...labels,
            ...masFeatureTrackingLabels
        }
        return {
            id: featureId,
            name: moduleType,
            position: featureOrder,
            status: status,
            labels: mergedLabels
        }
    }

    private createWidgetTileFeature(widgetTile: TrackingWidgetTile, parentFeatureId: string, status: FeatureStatus, moduleType: string): Feature {
        return {
            id: widgetTile.id,
            parentId: parentFeatureId,
            name: `${moduleType}-Tile`,
            position: widgetTile.position,
            status: status,
            labels: widgetTile.labels
        };
    }
}

export type TrackingWidgetTile = {
    id: string
    position: number
    labels: { [key: string]: string[] }
}